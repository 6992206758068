import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import chineseMessages from './i18n/zh';

import visitors from './visitors';
import orders from './orders';
import lights from './lights';
import invoices from './invoices';
import categories from './categories';
import reviews from './reviews';
import constantsDev from './constants.dev';
import constantsProd from './constants.prod';


import dataProviderFactory from './dataProvider';
// import fakeServerFactory from './fakeServer';

const constants = process.env.NODE_ENV==="development"?constantsDev:constantsProd;
const i18nProvider = polyglotI18nProvider(locale => {

    if(locale === "en") {
        return import('./i18n/en').then(messages=>messages.default);
    }

    // Always fallback on chinese
    return chineseMessages;
}, 'zh',// Default locale
{ 
    allowMissing: true
}    // Allow miss some translation and no waring message in console 
);

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);
    
    useEffect(() => {
        let restoreFetch;

        restoreFetch = ()=>{};
          
        const fetchDataProvider = async () => {

            const dataProviderInstance = await dataProviderFactory(
                constants.REACT_APP_DATA_PROVIDER
            );
            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="customers" {...visitors} />
            <Resource
                name="transactions"
                {...orders}
                options={{ label: 'Orders' }}
            />
            {/* <Resource name="invoices" {...invoices} /> */}
            <Resource name="lights" {...lights} />
            {/* <Resource name="categories" {...categories} />
            <Resource name="reviews" {...reviews} /> */}
        </Admin>
    );
};

export default App;
