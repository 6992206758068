import { AuthProvider } from 'ra-core';
import constantsDev from './constants.dev';
import constantsProd from './constants.prod';
const constants = process.env.NODE_ENV==="development"?constantsDev:constantsProd;


const authProvider: AuthProvider = {
    login: ({ username,password }) => {
        const url = `${constants.REACT_APP_DEFAULT_URL}/auth/local`
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify({ identifier:username, password:password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {

                if (response.status < 200 || response.status >= 300) {
                    let errorMessage= response.statusText;
                    response.json().then(body=>{
                        if(body.message)
                        {   
                        
                            const messages = body.message[0].messages;
                            messages.forEach((item:any)=>{
                                console.error(item.message);
                            })
                        }
                    })
                    throw new Error(errorMessage);
                }       
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('token', data.jwt);
                localStorage.setItem('email',data.user.email);
                localStorage.setItem('username',data.user.username);
            }).catch((error)=>{
                throw Error(error);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    checkError: (error) => 
    { 
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();},
    checkAuth: () =>
        localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject('Unknown method'),
};

export default authProvider;
