// in src/comments.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

import { DateField, EditButton, useTranslate, NumberField,TextField } from 'react-admin';

import AvatarField from './AvatarField';
import ColoredNumberField from './ColoredNumberField';
import SegmentsField from './SegmentsField';
import { Identifier } from 'ra-core';
import { Customer } from '../types';
import { FC } from 'react';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

interface Props {
    ids?: Identifier[];
    data?: { [key: string]: Customer };
    basePath?: string;
}

const IsFiredTextField = (record:any, source:any) => (
    <span>{record[source]?"已点燃":"未点燃"}</span>
);

const MobileGrid: FC<Props> = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useStyles();

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{`${data[id].TransactionID.DisplayPersonName}`}</h2>
                                {/* <EditButton
                                    resource="visitors"
                                    basePath={basePath}
                                    record={data[id]}
                                /> */}
                            </div>
                        }
                        // avatar={<AvatarField record={data[id]} size="45" />}
                    />
                    <CardContent className={classes.cardContent}>
                    <div>
                            {translate(
                                'resources.lights.fields.led_no',
                                data[id].nb_commands || 1
                            )}
                            &nbsp;:&nbsp;
                            <TextField
                                record={data[id]}
                                source="LedNo"
                                label="resources.lights.fields.led_no"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.lights.fields.end_date'
                            )}
                            &nbsp;
                            <DateField
                                record={data[id]}
                                source="EndDate"
                                type="date"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.lights.fields.is_fire',
                                data[id].nb_commands || 1
                            )}
                            &nbsp;:&nbsp;
                            <IsFiredTextField
                                record={data[id]}
                                source="IsFired"
                                label="resources.lights.fields.is_fire"
                            />
                        </div>
                    </CardContent>
                    {data[id].groups && data[id].groups.length > 0 && (
                        <CardContent className={classes.cardContent}>
                            <SegmentsField record={data[id]} />
                        </CardContent>
                    )}
                </Card>
            ))}
        </div>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
