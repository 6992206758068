import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    TextField,
    TextInput
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';

import SegmentsField from './SegmentsField';
import SegmentInput from './SegmentInput';
import CustomerLinkField from './CustomerLinkField';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';

const VisitorFilter = (props: any) => (
    <Filter {...props}>
        {/* <SearchInput source="_q" alwaysOn /> */}
        <TextInput source="Name" label="resources.customers.fields.name"/>
        <TextInput source="Tel1" label="resources.customers.fields.tel1"/>
        <TextInput source="Tel2" label="resources.customers.fields.tel2"/>
        {/* <SegmentInput /> */}
    </Filter>
);

const useStyles = makeStyles({
    nb_commands: { color: 'purple' },
});

const VisitorList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<VisitorFilter />}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid optimized rowClick="edit">
                    <CustomerLinkField label="resources.customers.fields.link"/>
                    <NumberField source="id" label="resources.customers.fields.id"/>
                    <TextField source="Name" label="resources.customers.fields.name"/>
                    <TextField source="Tel1" label="resources.customers.fields.tel1"/>
                    <TextField source="Tel2" label="resources.customers.fields.tel2"/>
                    <TextField source="Gender" label="resources.customers.fields.gender"/>
                    <TextField source="Birthday" label="resources.customers.fields.birthday"/>
                    {/* <NumberField
                        source="nb_commands"
                        label="resources.customers.fields.commands"
                        className={classes.nb_commands}
                    /> */}
                    {/* <ColoredNumberField
                        source="total_spent"
                        options={{ style: 'currency', currency: 'USD' }}
                    /> */}
                    <DateField source="created_at" type="date" label="resources.customers.fields.create_at"/>
                    <DateField source="updated_at" type="date" label="resources.customers.fields.update_at"/>
                    {/* <BooleanField source="has_newsletter" label="News." /> */}
                    {/* <SegmentsField /> */}
                </Datagrid>
            )}
        </List>
    );
};

export default VisitorList;
