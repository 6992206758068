// in src/comments.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import {
    DateField,
    EditButton,
    NumberField,
    TextField,
    BooleanField,
    useTranslate,
} from 'react-admin';

import CustomerReferenceField from '../visitors/CustomerReferenceField';

const useListStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
}));

const MobileGrid = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useListStyles();
    return (
        <div style={{ margin: '1em' }}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <span>
                                    {translate('resources.commands.name', 1)}
                                    &nbsp;
                                    <TextField
                                        record={data[id]}
                                        source="reference"
                                    />
                                </span>
                                {/* <EditButton
                                    resource="commands"
                                    basePath={basePath}
                                    record={data[id]}
                                /> */}
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.display_name')}:&nbsp;
                            <TextField
                                record={data[id]}
                                source="DisplayPersonName"
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.display_gender')}:&nbsp;
                            <TextField
                                record={data[id]}
                                source="DisplayPersonGender"
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.birth')}
                            :&nbsp;
                            <DateField record={data[id]} source="DisplayPersonBirthDay" />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.start_date')}
                            :&nbsp;
                            <DateField record={data[id]} source="StartDate" />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate(
                                'resources.commands.fields.days'
                            )}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="ExistedLightID.Days"
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.led_no')}:&nbsp;
                            <TextField
                                record={data[id]}
                                source="ExistedLightID.LedNo"
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.price')}:&nbsp;
                            <TextField
                                record={data[id]}
                                source="ExistedLightID.Price"
                            />
                        </span>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
