import { TranslationMessages } from 'ra-core';
import chineseMessages from 'ra-language-chinese';

const customEnglishMessages: TranslationMessages = {
    ...chineseMessages,
    pos: {
        search: 'Search',
        configuration: '设置',
        language: '语言',
        theme: {
            name: '主题',
            light: '亮色',
            dark: '暗色',
        },
        title:"供灯管理系统",
        dashboard: {
            monthly_revenue: '月收入',
            new_orders: '新订单',
            pending_reviews: 'Pending Reviews',
            new_customers: '新用户',
            pending_orders: '待付订单',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: '欢迎来到供灯管理系统',
                subtitle:
                    "这是供灯管理系统的后台界面，您可以使用此后台增加新的用户，给用户供灯并且查看供灯的情况",
            },
        },
        menu: {
            sales: '订单',
            lights: '供灯信息',
            customers: '客户',
        },
    },
    resources: {
        customers: {
            name: '客户 |||| 客户',
            fields: {
                // commands: 'Orders',
                link:"顾客",
                id:"编号",
                create_at:"创建时间",
                update_at:"更新时间",
                tel1:"电话1",
                tel2:"电话2",
                gender:"性别",
                birthday:"生日",
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: '姓名',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            fieldGroups: {
                identity: '基本信息',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: '订单',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: '状态',
                returned: 'Returned',
                id:"序号",
                days:"供灯时间(天)",
                birth:"祈福人生日",
                start_date:"祈福开始时间",
                display_name:"祈福人姓名",
                display_gender:"祈福人性别",
                led_no:"灯号",
                price:"灯位价格"

            },
            tabs:{
                paid:"已付款",
                unpaid:"未付款"
            }
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        lights: {
            name: '点燃灯位',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
                end_date:'结束日期',
                create_at:"创建时间",
                update_at:"更新时间",
                id:"编号",
                display_name:"祈福人姓名",
                is_fire:"是否点燃",
                led_no:"灯号",
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
    },
};

export default customEnglishMessages;
