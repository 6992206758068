import React, { Fragment } from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    SearchInput,
    TextField,
    TextInput,
} from 'react-admin';
import {
    makeStyles,
    useMediaQuery,
    Divider,
    Tabs,
    Tab,
} from '@material-ui/core';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import MobileGrid from './MobileGrid';

const OrderFilter = props => (
    <Filter {...props}>
        {/* <SearchInput source="_q" alwaysOn /> */}
        {/* <ReferenceInput source="customer_id" reference="customers">
            <AutocompleteInput
                optionText={choice =>
                    choice.first_name && choice.last_name
                        ? `${choice.first_name} ${choice.last_name}`
                        : ''
                }
            />
        </ReferenceInput> */}
        {/* <DateInput source="date_gte" />
        <DateInput source="date_lte" /> */}
        
        <TextInput source="DisplayPersonName" label="resources.commands.fields.display_name"/>
        <DateInput source="DisplayPersonBirthDay" label="resources.commands.fields.birth"/>
        {/* <TextInput source="ExistedLightID.LedNo" label="resources.commands.fields.led_no"/> */}

    </Filter>
);

const useDatagridStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

class TabbedDatagrid extends React.Component {
    tabs = [
        { id: 'paid', name: '已付款' },
        { id: 'unpaid', name: '未付款' },
    ];

    state = { paid: [], unpaid: []};

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;

        setFilters({ ...filterValues, status: value });
    };

    render() {
        const { isXSmall, classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    variant="fullWidth"
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                {isXSmall ? (
                    <MobileGrid
                        {...props}
                        ids={this.state[filterValues.status]}
                    />
                ) : (
                    <div>
                        {filterValues.status === "paid" && (
                            <Datagrid
                                {...props}
                                ids={this.state.paid}
                                optimized
                            >
                             <TextField source="id" label="resources.commands.fields.id"/>
                             <DateField source="StartDate" label="resources.commands.fields.start_date"/>
                             <NumberField source="ExistedLightID.Days" label="resources.commands.fields.days"/>
                             <TextField source="DisplayPersonName" label="resources.commands.fields.display_name"/>
                             <TextField source="DisplayPersonGender" label="resources.commands.fields.display_gender"/>
                             <DateField source="DisplayPersonBirthDay" label="resources.commands.fields.birth"/>
                             <TextField source="ExistedLightID.LedNo" label="resources.commands.fields.led_no"/>
                             <TextField source="ExistedLightID.Price" label="resources.commands.fields.price"/>
                         
                                {/* <DateField source="date" showTime />
                                <TextField source="reference" />
                                <CustomerReferenceField />
                                <NbItemsField />
                                <NumberField
                                    source="total"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    className={classes.total}
                                /> */}
                                {/* <DateField source="DisplayPersonBirthDay"/> */}
                            </Datagrid>
                        )}
                        {filterValues.status === "unpaid" && (
                            <Datagrid {...props} ids={this.state.unpaid}>
                              <TextField source="id" label="resources.commands.fields.id"/>
                             <DateField source="StartDate" label="resources.commands.fields.start_date"/>
                             <NumberField source="ExistedLightID.Days" label="resources.commands.fields.days"/>
                             <TextField source="DisplayPersonName" label="resources.commands.fields.display_name"/>
                             <TextField source="DisplayPersonGender" label="resources.commands.fields.display_gender"/>
                             <DateField source="DisplayPersonBirthDay" label="resources.commands.fields.birth"/>
                             <TextField source="ExistedLightID.LedNo" label="resources.commands.fields.led_no"/>
                             <TextField source="ExistedLightID.Price" label="resources.commands.fields.price"/>
                                {/* <DateField source="date" showTime />
                                <TextField source="reference" />
                                <CustomerReferenceField />
                                <NbItemsField />
                                <NumberField
                                    source="total"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    className={classes.total}
                                />
                                <BooleanField source="returned" />
                                <EditButton /> */}
                            </Datagrid>
                        )}
                        {/* {filterValues.status === 'cancelled' && (
                            <Datagrid {...props} ids={this.state.cancelled}>
                                <DateField source="date" showTime />
                                <TextField source="reference" />
                                <CustomerReferenceField />
                                <NbItemsField />
                                <NumberField
                                    source="total"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    className={classes.total}
                                />
                                <BooleanField source="returned" />
                                <EditButton />
                            </Datagrid>
                        )} */}
                    </div>
                )}
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = props => {
    const classes = useDatagridStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    return <TabbedDatagrid classes={classes} isXSmall={isXSmall} {...props} />;
};

const OrderList = ({ classes, ...props }) => (
    <List
        {...props}
        filterDefaultValues={{ status: "paid" }}
        sort={{ field: 'Id', order: 'DESC' }}
        perPage={25}
        filters={<OrderFilter />}
        title="resources.commands.name"

    >
        <StyledTabbedDatagrid />
    </List>
);

export default OrderList;
