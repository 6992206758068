import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    TextField,
    BooleanInput
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';

import SegmentsField from './SegmentsField';
import SegmentInput from './SegmentInput';
import CustomerLinkField from './CustomerLinkField';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';

const LightFilter = (props: any) => (
    <Filter {...props}>
        {/* <SearchInput source="_q" alwaysOn /> */}
        {/* <DateInput source="last_seen_gte" /> */}
        <BooleanInput source="IsFired" defaultValue label="resources.lights.fields.is_fire"/>
            {/* <SegmentInput /> */}
    </Filter>
);

const useStyles = makeStyles({
    nb_commands: { color: 'purple' },
});

const IsFiredTextField = (record:any, source:any) => (
    <span>{record[source]?"已点燃":"未点燃"}</span>
);

const LightList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<LightFilter />}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
            title = "resources.lights.name"
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid optimized >
                    <NumberField source="id" label="resources.lights.fields.id"/>
                    <TextField source="TransactionID.DisplayPersonName" label="resources.lights.fields.display_name"/>
                    <IsFiredTextField source="IsFired" label="resources.lights.fields.is_fire"/>
                    <TextField source="LedNo" label="resources.lights.fields.led_no" />
                    <TextField source="EndDate" label="resources.lights.fields.end_date"/>
                    <DateField source="created_at" type="date" label="resources.lights.fields.create_at"/>
                    <DateField source="updated_at" type="date" label="resources.lights.fields.update_at"/>
                </Datagrid>
            )}
        </List>
    );
};

export default LightList;
