import VisitorIcon from '@material-ui/icons/People';

import LightList from './LightList';
import LightCreate from './LightCreate';
import LightEdit from './LightEdit';

export default {
    list: LightList,
    // create: LightCreate,
    edit: LightEdit,
    icon: VisitorIcon,
};
